import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import generalUtils from '../../utils';
import { TooltipTypes } from '../../utils/tooltipConstants';
import { isTi } from '../../utils/targetApplication';
import { TEXT_KEY, getText } from '../localization';
export var missionFinished = function missionFinished(RFValue, deviceInfo) {
  var detail = {
    popUpType: TooltipTypes.MISSIONS,
    title: getText(TEXT_KEY.MISSION_DONE_CLAIM_REWARD_TOOLTIP)
  };

  if (generalUtils.getPlatform() === 'web') {
    detail = _objectSpread(_objectSpread({}, detail), {}, {
      animationState: 'AnimationSlideFromTop',
      style: {
        zIndex: 1000,
        left: tooltipPositionCheck()
      }
    });
  } else {
    detail = _objectSpread(_objectSpread({}, detail), {}, {
      animationState: 'in',
      containerStyle: {
        width: RFValue(200),
        height: deviceInfo.isTablet() ? RFValue(50) : RFValue(54),
        top: deviceInfo.isTablet() ? '12%' : '16%',
        right: '9%'
      }
    });
  }

  return detail;
};
export var missionInDangerZone = function missionInDangerZone(RFValue, deviceInfo) {
  var detail = {
    popUpType: TooltipTypes.MISSIONS,
    title: getText(TEXT_KEY.MISSION_ENDS_SOON_TOOLTIP)
  };

  if (generalUtils.getPlatform() === 'web') {
    detail = _objectSpread(_objectSpread({}, detail), {}, {
      animationState: 'AnimationSlideFromTop',
      style: {
        zIndex: 1000,
        left: tooltipPositionCheck()
      }
    });
  } else {
    detail = _objectSpread(_objectSpread({}, detail), {}, {
      animationState: 'in',
      containerStyle: {
        width: RFValue(210),
        height: deviceInfo.isTablet() ? RFValue(50) : RFValue(55),
        top: deviceInfo.isTablet() ? '12%' : '16%',
        right: '9%'
      }
    });
  }

  return detail;
};

var tooltipPositionCheck = function tooltipPositionCheck() {
  var _window = window,
      width = _window.innerWidth;
  var leftPosition;

  if (isTi) {
    leftPosition = width < 1600 ? '60%' : width < 2000 ? '64%' : '62.5%';
  } else {
    leftPosition = width < 1600 ? '60%' : '57%';
  }

  return leftPosition;
};