import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { SET_ONBOARDING_HIGHLIGHTED_POSITION, SET_ONBOARDING_STEP, SET_FTUE_STEP, CLEAR_ONBOARDING, SET_FTUE_STATE, SET_FTUE_GAME } from '../actions/types';
var initialState = {
  ftueEnabled: false,
  ftueGameId: null,
  position: null,
  // { x: 50, y: 50, width: 100, height: 100 },
  tooltip: {
    message: null,
    customStyles: null,
    position: null
  },
  onboardingName: null,
  // 'favourites',
  currentStep: null,
  // 'favourites_1',
  ftueStep: null // **0-> set scroll **1-> highlight a game **2-> show in game highlight **3-> clicked on spinner **4-> finished and showing the popup

};

var currentOnboardingReducer = function currentOnboardingReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_ONBOARDING_HIGHLIGHTED_POSITION:
      return _objectSpread(_objectSpread({}, state), {}, {
        position: action.payload.position,
        tooltip: action.payload.tooltip
      });

    case SET_ONBOARDING_STEP:
      return _objectSpread(_objectSpread({}, state), {}, {
        currentStep: action.payload
      });

    case SET_FTUE_GAME:
      return _objectSpread(_objectSpread({}, state), {}, {
        ftueGameId: action.payload
      });

    case SET_FTUE_STATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        ftueEnabled: action.payload
      });

    case SET_FTUE_STEP:
      return _objectSpread(_objectSpread({}, state), {}, {
        position: null,
        tooltip: {
          message: null,
          customStyles: null,
          position: null
        },
        ftueStep: action.payload
      });

    case CLEAR_ONBOARDING:
      return initialState;

    default:
      return state;
  }
};

export default currentOnboardingReducer;