import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { ADD_NOTIFICATION, REMOVE_NOTIFICATION, SEND_PUSH_NOTIFICATION, REMOVE_PUSH_NOTIFICATION, UPDATE_MAILBOX_UNREAED_MESSAGE } from '../actions/types';
var initialState = {
  unreadMessagesCount: 0,
  notificationsSent: {},
  toasts: []
};
var toastCounter = 0;
export var notificationReducer = function notificationReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case ADD_NOTIFICATION:
      toastCounter++;
      return _objectSpread(_objectSpread({}, state), {}, {
        toasts: [_objectSpread(_objectSpread({}, state.toasts), {}, {
          id: toastCounter
        }, action.notif)]
      });

    case SEND_PUSH_NOTIFICATION:
      var playerNotifications = state.notificationsSent[action.payload.playerId] || [];
      playerNotifications.push(action.payload.notifiedFeature);
      return _objectSpread(_objectSpread({}, state), {}, {
        notificationsSent: _objectSpread(_objectSpread({}, state.notificationsSent), {}, _defineProperty({}, action.payload.playerId, playerNotifications))
      });

    case REMOVE_PUSH_NOTIFICATION:
      var availableNotifications = state.notificationsSent[action.payload.playerId];

      if (availableNotifications) {
        return _objectSpread(_objectSpread({}, state), {}, {
          notificationsSent: _objectSpread(_objectSpread({}, state.notificationsSent), {}, _defineProperty({}, action.payload.playerId, availableNotifications.filter(function (item) {
            return item !== action.payload.notifiedFeature;
          })))
        });
      }

      return state;

    case REMOVE_NOTIFICATION:
      return state.toasts.filter(function (item) {
        return item.id !== action.id;
      });

    case UPDATE_MAILBOX_UNREAED_MESSAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        unreadMessagesCount: action.payload
      });

    default:
      return state;
  }
};
export default notificationReducer;