import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { SET_COIN, ADD_COINS, SUBTRACT_COINS, SET_LOYALTY, SET_PREMIUM, SET_LTO_STATE, SET_LAST_WIN, SET_VIDEO_ADS_INFO, SET_TIME_REMAINING, SET_DEFAULT_VALUES_FOR_APPSFLYER_LINKS, ADD_LOYALTY } from '../actions/types';
var initialState = {
  value: 0
};
export var coinReducer = function coinReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_COIN:
      return _objectSpread(_objectSpread({}, state), {}, {
        value: action.payload
      });

    case ADD_COINS:
      return _objectSpread(_objectSpread({}, state), {}, {
        value: parseInt(state.value) + action.payload
      });

    case SUBTRACT_COINS:
      return _objectSpread(_objectSpread({}, state), {}, {
        value: parseInt(state.value) - action.payload
      });

    default:
      return state;
  }
};
export var premiumReducer = function premiumReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_PREMIUM:
      return _objectSpread(_objectSpread({}, state), {}, {
        value: action.payload
      });

    default:
      return state;
  }
};
export var loyaltyReducer = function loyaltyReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_LOYALTY:
      return _objectSpread(_objectSpread({}, state), {}, {
        value: action.payload
      });

    case ADD_LOYALTY:
      return _objectSpread(_objectSpread({}, state), {}, {
        value: parseInt(state.value) + action.payload
      });

    default:
      return state;
  }
};
export var ltoReducer = function ltoReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_LTO_STATE:
      return action.payload;

    default:
      return state;
  }
};
export var lastWinReducer = function lastWinReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_LAST_WIN:
      return _objectSpread(_objectSpread({}, state), {}, {
        value: parseInt(action.payload)
      });

    default:
      return state;
  }
};
export var videoAdsReducer = function videoAdsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_VIDEO_ADS_INFO:
      var secondsUntilReset = parseInt(action.payload.secondsUntilReset);
      return _objectSpread(_objectSpread({}, state), {}, {
        remaining: parseInt(action.payload.remaining),
        secondsUntilReset: secondsUntilReset,
        refreshDate: Date.now() + secondsUntilReset * 1000
      });

    default:
      return state;
  }
};
export var timeRemainingReducer = function timeRemainingReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_TIME_REMAINING:
      return _objectSpread(_objectSpread({}, state), {}, {
        timeRemaining: action.payload.time,
        activeFeature: action.payload.feature
      });

    default:
      return state;
  }
};
export var appsFlyerValidationReducer = function appsFlyerValidationReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_DEFAULT_VALUES_FOR_APPSFLYER_LINKS:
      var _action$payload = action.payload,
          validLink = _action$payload.validLink,
          iterationCount = _action$payload.iterationCount;
      return _objectSpread(_objectSpread({}, state), {}, {
        isValidLink: validLink,
        iterationCount: iterationCount
      });

    default:
      return state;
  }
};