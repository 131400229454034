import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { SET_AUTHORIZED, SET_TOKEN, SET_RESTART, CLEAR_TOKEN, SET_LAST_ACTIVE, SET_FB_TOKEN, UPDATE_LAST_LOGIN_INFO, CLEAR_LOGIN_INFO, CLEAR_GOOGLE_TOKEN, SET_GOOGLE_TOKEN } from '../actions/types';
var initialState = {
  token: '',
  fb_token: '',
  google_token: '',
  lastLoginInfo: {}
};
export var fbTokenReducer = function fbTokenReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_FB_TOKEN:
      return _objectSpread(_objectSpread({}, state), {}, {
        fb_token: action.payload
      });

    case CLEAR_TOKEN:
      return _objectSpread(_objectSpread({}, state), {}, {
        fb_token: ''
      });

    default:
      return state;
  }
};
export var tokenReducer = function tokenReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_TOKEN:
      return _objectSpread(_objectSpread({}, state), {}, {
        token: action.payload
      });

    case CLEAR_TOKEN:
      return _objectSpread(_objectSpread({}, state), {}, {
        token: ''
      });

    case UPDATE_LAST_LOGIN_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        lastLoginInfo: action.payload
      });

    case CLEAR_LOGIN_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        lastLoginInfo: {}
      });

    case SET_GOOGLE_TOKEN:
      return _objectSpread(_objectSpread({}, state), {}, {
        google_token: action.payload
      });

    case CLEAR_GOOGLE_TOKEN:
      return _objectSpread(_objectSpread({}, state), {}, {
        google_token: ''
      });

    default:
      return state;
  }
};
export var restartReducer = function restartReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_RESTART:
      return _objectSpread(_objectSpread({}, state), {}, {
        token: action.payload
      });

    case CLEAR_TOKEN:
      return _objectSpread(_objectSpread({}, state), {}, {
        token: ''
      });

    default:
      return state;
  }
};
export var authorizedReducer = function authorizedReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    authorized: false
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_AUTHORIZED:
      return _objectSpread(_objectSpread({}, state), {}, {
        authorized: action.payload
      });

    default:
      return state;
  }
};
export var lastActiveReducer = function lastActiveReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    timestamp: null
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_LAST_ACTIVE:
      return _objectSpread(_objectSpread({}, state), {}, {
        timestamp: action.payload
      });

    case CLEAR_TOKEN:
      return _objectSpread(_objectSpread({}, state), {}, {
        timestamp: ''
      });

    default:
      return state;
  }
};