import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { SET_ACTIVE_CONSUMABLES, SET_FREE_CONSUMABLES, SHOW_VIP_ACCESS_MODAL, SET_VIP_ROOM_UNLOCKED, SET_OUT_OF_COINS_OFFER, SET_LTO_OFFER, SET_WELCOME_BONUS, SET_TIMED_BONUS, SET_IS_WELCOME_BONUS_CONSUMED, CLEAR_LTO_OFFER } from '../actions/types';
var initialState = {
  active: {},
  free: {},
  showVipAccessModal: true,
  isVipRoomUnlocked: false,
  outOfCoins: {},
  ltoOffer: {},
  welcomeBonus: '',
  isWelcomeBonusConsumed: false,
  timedBonus: {}
};
export var consumablesReducer = function consumablesReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_ACTIVE_CONSUMABLES:
      return _objectSpread(_objectSpread({}, state), {}, {
        active: action.payload
      });

    case SET_FREE_CONSUMABLES:
      return _objectSpread(_objectSpread({}, state), {}, {
        free: action.payload
      });

    case SHOW_VIP_ACCESS_MODAL:
      return _objectSpread(_objectSpread({}, state), {}, {
        showVipAccessModal: action.payload
      });

    case SET_VIP_ROOM_UNLOCKED:
      return _objectSpread(_objectSpread({}, state), {}, {
        isVipRoomUnlocked: action.payload
      });

    case SET_OUT_OF_COINS_OFFER:
      return _objectSpread(_objectSpread({}, state), {}, {
        outOfCoins: action.payload
      });

    case SET_LTO_OFFER:
      return _objectSpread(_objectSpread({}, state), {}, {
        ltoOffer: action.payload
      });

    case CLEAR_LTO_OFFER:
      return _objectSpread(_objectSpread({}, state), {}, {
        ltoOffer: initialState.ltoOffer
      });

    case SET_WELCOME_BONUS:
      return _objectSpread(_objectSpread({}, state), {}, {
        welcomeBonus: action.payload
      });

    case SET_TIMED_BONUS:
      return _objectSpread(_objectSpread({}, state), {}, {
        timedBonus: action.payload
      });

    case SET_IS_WELCOME_BONUS_CONSUMED:
      return _objectSpread(_objectSpread({}, state), {}, {
        isWelcomeBonusConsumed: action.payload
      });

    default:
      return state;
  }
};