import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { FTUE_EVENT_TYPES } from '../../../constants';
import { MISSIONS_EVENT_TYPES } from '../../../screens/Missions/constants';
import { ACTION_COMMANDS_EVENT_TYPE } from '../../../api/ActionCommandsInterface/constants';
import { EVENTS_EVENT_TYPES } from '../../../screens/Events/constants';
/**
 * EVENT_TYPES should remain as abstract as possible to encourage cross-platform
 * implementation.
 *
 * Consider using VIEW (which makes sense on all platforms)
 * instead of PAGE_VIEW (which only really makes sense on web)
 */

export var EVENT_TYPES = _objectSpread(_objectSpread(_objectSpread(_objectSpread({
  VIEW: 'VIEW',
  FRONTEND_ERROR: 'FRONTEND_ERROR',
  REGISTRATION_COMPLETE: 'REGISTRATION_COMPLETE',
  PURCHASE_COMPLETE: 'PURCHASE_COMPLETE',
  TUTORIAL_COMPLETE: 'TUTORIAL_COMPLETE',
  LOGIN_COMPLETE: 'LOGIN_COMPLETE',
  TUTORIAL_STARTED: 'TUTORIAL_STARTED',
  TUTORIAL_STEP_COMPLETE: 'TUTORIAL_STEP_COMPLETE',
  TUTORIAL_SKIPPED: 'TUTORIAL_SKIPPED',
  GAME_INIT: 'GAME_INIT',
  GAME_LOADED: 'GAME_LOADED',
  STORE_OPENED: 'STORE_OPENED',
  BONUS_LINK_CLAIMED: 'BONUS_LINK_CLAIMED',
  DAILY_WHEEL_CLAIMED: 'DAILY_WHEEL_CLAIMED',
  AD_BONUS_CLAIMED: 'AD_BONUS_CLAIMED',
  LEVEL_UP: 'LEVEL_UP',
  INBOX_MESSAGE_ACTION_CLICKED: 'INBOX_MESSAGE_ACTION_CLICKED',
  OFFER_REDEEMED: 'OFFER_REDEEMED',
  MEMBER_ACCOUNT_LINKED: 'MEMBER_ACCOUNT_LINKED',
  RATE_APP_POPUP_SHOWED: 'RATE_APP_POPUP_SHOWED',
  USER_APP_RATED: 'USER_APP_RATED',
  FAVOURITE_GAME_ADDED: 'FAVOURITE_GAME_ADDED',
  FAVOURITE_GAME_REMOVED: 'FAVOURITE_GAME_REMOVED',
  GAME_ROOM_LOADED: 'GAME_ROOM_LOADED',
  KYC_BASIC_CHECK_INIT: 'KYC_BASIC_CHECK_INIT',
  KYC_BASIC_CHECK_COMPLETED: 'KYC_BASIC_CHECK_COMPLETED',
  KYC_BASIC_CHECK_FAILED: 'KYC_BASIC_CHECK_FAILED',
  KYC_DOCUMENT_CHECK_INIT: 'KYC_DOCUMENT_CHECK_INIT',
  KYC_DOCUMENT_CHECK_COMPLETED: 'KYC_DOCUMENT_CHECK_COMPLETED',
  KYC_DOCUMENT_CHECK_FAILED: 'KYC_DOCUMENT_CHECK_FAILED',
  OOC_POP_UP_SHOWED: 'OOC_POP_UP_SHOWED',
  TIMED_BONUS_CLAIMED: 'TIMED_BONUS_CLAIMED',
  TRANSACTION_INITIATED: 'TRANSACTION_INITIATED',
  TRANSACTION_FAILED: 'TRANSACTION_FAILED',
  SETTING_AMBIENT_SOUND_TOGGLE: 'SETTING_AMBIENT_SOUND_TOGGLE',
  SETTING_SOUND_EFFECTS_TOGGLE: 'SETTING_SOUND_EFFECTS_TOGGLE',
  USER_CONTACTED_SUPPORT: 'USER_CONTACTED_SUPPORT',
  RATE_APP_POPUP_CLOSED: 'RATE_APP_POPUP_CLOSED',
  AD_FINISHED: 'AD_FINISHED',
  AD_STARTED: 'AD_STARTED',
  SETTINGS_PROMO_EMAIL_TOGGLE: 'SETTINGS_PROMO_EMAIL_TOGGLE',
  REWARD_CENTER_OPENED: 'REWARD_CENTER_OPENED',
  ERROR_CREATED: 'ERROR_CREATED',
  USER_ACCOUNT_LINKED: 'USER_ACCOUNT_LINKED',
  SUCCESFUL_LOGIN: 'SUCCESFUL_LOGIN',
  REQUEST_SENT: 'REQUEST_SENT',
  RESPONSE_RECEIVED: 'RESPONSE_RECEIVED',
  GAMES_OPENED_AMOUNT: 'GAMES_OPENED_AMOUNT',
  ACCOUNT_DELETION_REQUESTED: 'ACCOUNT_DELETION_REQUESTED',
  MISS_YOU_NOTIFICATION_INTERACTION: 'MISS_YOU_NOTIFICATION_INTERACTION',
  PROMOLINK_CLICKED: 'PROMOLINK_CLICKED'
}, MISSIONS_EVENT_TYPES), FTUE_EVENT_TYPES), ACTION_COMMANDS_EVENT_TYPE), EVENTS_EVENT_TYPES);