import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _handlerRewardsClaim;

import { addCoins, addLoyalty } from '../../../state/actions/extras';
import { addXp } from '../../../state/slices/xp/xp';
import store from '../../../state/store';
import { MISSIONS_REWARD_BUNDLE_TYPE } from '../constants';

var rewardTypeCoins = function rewardTypeCoins(rewardItems) {
  return store.dispatch(addCoins(rewardItems[0].rewardValue));
};

var rewardTypeLoyalty = function rewardTypeLoyalty(rewardItems) {
  return store.dispatch(addLoyalty(rewardItems[0].rewardValue));
};

var rewardTypeCoinsAndXPPoints = function rewardTypeCoinsAndXPPoints(rewardItems) {
  store.dispatch(addCoins(rewardItems[0].rewardValue));
  store.dispatch(addXp(rewardItems[1].rewardValue));
};

var handlerRewardsClaim = (_handlerRewardsClaim = {}, _defineProperty(_handlerRewardsClaim, MISSIONS_REWARD_BUNDLE_TYPE.COINS, rewardTypeCoins), _defineProperty(_handlerRewardsClaim, MISSIONS_REWARD_BUNDLE_TYPE.LOYALTY_POINTS, rewardTypeLoyalty), _defineProperty(_handlerRewardsClaim, MISSIONS_REWARD_BUNDLE_TYPE.COINS_AND_XP_POINTS, rewardTypeCoinsAndXPPoints), _handlerRewardsClaim);

var missionsRewardsClaim = function missionsRewardsClaim(_ref) {
  var rewardBundleType = _ref.rewardBundleType,
      rewardItems = _ref.rewardItems,
      rewardValue = _ref.rewardValue;

  if (rewardBundleType === MISSIONS_REWARD_BUNDLE_TYPE.MYSTERY_GIFT) {
    handlerRewardsClaim[rewardValue.rewardItems[0].rewardType](rewardItems[0].rewardValue.rewardItems);
  } else handlerRewardsClaim[rewardBundleType](rewardItems);
};

export default missionsRewardsClaim;