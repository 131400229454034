import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _ICON_TOOLTIP_TEXT;

import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { tooltipDetails, shouldShowTooltip } from '../../../state/actions/tooltip';
import { EventsIconTootipDetail } from '../tooltips';
import { EVENTS_FEATURE_STATUS } from '../constants';
import { useDispatch } from 'react-redux';
import { TEXT_KEY, getText } from '../../../utils/localization';
import screens from '../../../screens';
var ICON_TOOLTIP_TEXT = (_ICON_TOOLTIP_TEXT = {}, _defineProperty(_ICON_TOOLTIP_TEXT, EVENTS_FEATURE_STATUS.MAINTENANCE, getText(TEXT_KEY.EVENTS_ERROR_TOOLTIP)), _defineProperty(_ICON_TOOLTIP_TEXT, EVENTS_FEATURE_STATUS.COMING_SOON, getText(TEXT_KEY.EVENTS_COMING_TOOLTIP)), _defineProperty(_ICON_TOOLTIP_TEXT, EVENTS_FEATURE_STATUS.MUST_UPDATE, getText(TEXT_KEY.EVENTS_MUST_UPDATE_TOOLTIP)), _defineProperty(_ICON_TOOLTIP_TEXT, "default", getText(TEXT_KEY.EVENTS_ERROR_TOOLTIP)), _ICON_TOOLTIP_TEXT);
export default function useEventsIconTooltip(_ref) {
  var eventsState = _ref.eventsState,
      navigation = _ref.navigation;

  var _useSelector = useSelector(function (state) {
    return state.modal;
  }),
      modal = _useSelector.modal;

  var actionCommands = useSelector(function (state) {
    return state.actionCommands.actions;
  });
  var firstCycle = useRef(true);
  var showTooltipIsChecked = useRef(false);
  var dispatch = useDispatch();

  var handleEventsIconTooltip = function handleEventsIconTooltip() {
    var detail = EventsIconTootipDetail(ICON_TOOLTIP_TEXT[eventsState] || ICON_TOOLTIP_TEXT.default);
    dispatch(tooltipDetails(detail));
    dispatch(shouldShowTooltip(true));
    setTimeout(function () {
      dispatch(shouldShowTooltip(false));
    }, 2000);
  };

  var showTooltipOnAppOpen = useCallback(function () {
    /**
     * 1- modal on first cycle is always null so we need to skip it
     * 2- We need a timeout to ensure the bottom bar appears correctly after the slide-up intro animation (web)
     */
    if (firstCycle.current) {
      firstCycle.current = false;
      return;
    }

    if (modal || (actionCommands === null || actionCommands === void 0 ? void 0 : actionCommands.length) !== 0 || navigation.state.routes[navigation.state.index].key !== screens.Home || showTooltipIsChecked.current) return;
    showTooltipIsChecked.current = true;
    setTimeout(function () {
      eventsState !== EVENTS_FEATURE_STATUS.ACTIVE && handleEventsIconTooltip();
    }, 2000); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsState, modal, actionCommands]);
  useEffect(function () {
    return showTooltipOnAppOpen();
  }, [showTooltipOnAppOpen]);
  return {
    handleEventsIconTooltip: handleEventsIconTooltip
  };
}