import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { SET_SHOP_LIST_COINS, SET_SHOP_LIST_VIP, SET_SALE_AVAILABLE, SET_USED_PROMO_CODE, SET_PAYMENT_PROVIDER } from '../actions/types';
var initialState = {
  coins: [],
  vip: [],
  saleAvailable: false,
  lastUsedPromoCode: null,
  paymentProvider: null
};
export var shopListReducer = function shopListReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_SHOP_LIST_COINS:
      return _objectSpread(_objectSpread({}, state), {}, {
        coins: action.payload
      });

    case SET_SHOP_LIST_VIP:
      return _objectSpread(_objectSpread({}, state), {}, {
        vip: action.payload
      });

    case SET_SALE_AVAILABLE:
      return _objectSpread(_objectSpread({}, state), {}, {
        saleAvailable: action.payload
      });

    case SET_USED_PROMO_CODE:
      return _objectSpread(_objectSpread({}, state), {}, {
        lastUsedPromoCode: action.payload
      });

    case SET_PAYMENT_PROVIDER:
      return _objectSpread(_objectSpread({}, state), {}, {
        paymentProvider: action.payload
      });

    default:
      return state;
  }
};