import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { EVENTS_CARD_TYPE, EVENTS_GAMEPLAY_STATUS } from '../constants';

var generateSingleGameCard = function generateSingleGameCard(eventsAssets) {
  return {
    BackgroundCard: eventsAssets.EVENTS_CARD_PURPLE,
    GlowCard: eventsAssets.EVENTS_CARD_FRAME_GLOW,
    BottomCard: eventsAssets.EVENTS_CARD_BOTTOM_PURPLE,
    BackgroundDetail: eventsAssets.EVENTS_DETAIL_CARD_PURPLE,
    EntriesCounterDetail: eventsAssets.EVENTS_DETAIL_COUNTER_PURPLE,
    EntriesBarDetail: eventsAssets.EVENTS_DETAIL_BAR_PURPLE,
    Ribbon: eventsAssets.EVENTS_DEFAULT_RIBBON,
    PodiumBottom: eventsAssets.EVENTS_PODIUM_ACTIVE,
    StrokeColor: '#220d55'
  };
};

var generateMultipleGamesCard = function generateMultipleGamesCard(eventsAssets) {
  return {
    BackgroundCard: eventsAssets.EVENTS_CARD_MULTIlPLE_GAMES,
    GlowCard: eventsAssets.EVENTS_CARD_FRAME_GLOW,
    BottomCard: eventsAssets.EVENTS_CARD_BOTTOM_BLUE,
    Title: eventsAssets.EVENTS_MULTIPLE_GAMES_TITLE,
    BackgroundDetail: eventsAssets.EVENTS_DETAIL_CARD_BLUE,
    EntriesCounterDetail: eventsAssets.EVENTS_DETAIL_COUNTER_BLUE,
    EntriesBarDetail: eventsAssets.EVENTS_DETAIL_BAR_BLUE,
    Ribbon: eventsAssets.EVENTS_DEFAULT_RIBBON,
    PodiumBottom: eventsAssets.EVENTS_PODIUM_ACTIVE,
    StrokeColor: '#213787'
  };
};

var generateMultipleActivitiesCard = function generateMultipleActivitiesCard(eventsAssets) {
  return {
    BackgroundCard: eventsAssets.EVENTS_CARD_MULTIlPLE_ACTIVITIES,
    GlowCard: eventsAssets.EVENTS_CARD_FRAME_GLOW,
    BottomCard: eventsAssets.EVENTS_CARD_BOTTOM_PURPLE,
    Title: eventsAssets.EVENTS_MULTIPLE_ACTIVITIES_TITLE,
    BackgroundDetail: eventsAssets.EVENTS_DETAIL_CARD_PURPLE,
    EntriesCounterDetail: eventsAssets.EVENTS_DETAIL_COUNTER_PURPLE,
    EntriesBarDetail: eventsAssets.EVENTS_DETAIL_BAR_PURPLE,
    Ribbon: eventsAssets.EVENTS_DEFAULT_RIBBON,
    PodiumBottom: eventsAssets.EVENTS_PODIUM_ACTIVE,
    StrokeColor: '#220d55'
  };
};

var generateTopBannerCard = function generateTopBannerCard(eventsAssets) {
  return {
    BackgroundCard: eventsAssets.EVENTS_CARD_ORANGE,
    GlowCard: eventsAssets.EVENTS_CARD_FRAME_GLOW_LIVE_OPS,
    BottomCard: eventsAssets.EVENTS_CARD_BOTTOM_ORANGE,
    BackgroundDetail: eventsAssets.EVENTS_DETAIL_CARD_ORANGE,
    EntriesCounterDetail: eventsAssets.EVENTS_DETAIL_COUNTER_ORANGE,
    EntriesBarDetail: eventsAssets.EVENTS_DETAIL_FILLABLE_BAR_BASE_ORANGE,
    EntriesBarFillDetail: eventsAssets.EVENTS_DETAIL_FILLABLE_BAR_FILL_ORANGE,
    EntriesBarEndDetail: eventsAssets.EVENTS_DETAIL_FILLABLE_BAR_CAP_ORANGE,
    Ribbon: eventsAssets.EVENTS_DEFAULT_RIBBON,
    PodiumBottom: eventsAssets.EVENTS_PODIUM_ACTIVE,
    TopBanner: eventsAssets.EVENTS_CARD_IMAGE_LIVE_OPS,
    StrokeColor: '#a44038'
  };
};

var generateAllGamesCard = function generateAllGamesCard(eventsAssets) {
  return {
    BackgroundCard: eventsAssets.EVENTS_CARD_ALL_GAMES,
    GlowCard: eventsAssets.EVENTS_CARD_FRAME_GLOW,
    BottomCard: eventsAssets.EVENTS_CARD_BOTTOM_PINK,
    Title: eventsAssets.EVENTS_ALL_GAMES_TITLE,
    BackgroundDetail: eventsAssets.EVENTS_DETAIL_CARD_PINK,
    EntriesCounterDetail: eventsAssets.EVENTS_DETAIL_COUNTER_PINK,
    EntriesBarDetail: eventsAssets.EVENTS_DETAIL_BAR_PINK,
    Ribbon: eventsAssets.EVENTS_DEFAULT_RIBBON,
    PodiumBottom: eventsAssets.EVENTS_PODIUM_ACTIVE,
    StrokeColor: '#732c5d'
  };
};

var generateTitleCard = function generateTitleCard(cardType, eventsAssets) {
  var _EVENTS_CARD_TYPE$MUL;

  return (_EVENTS_CARD_TYPE$MUL = {}, _defineProperty(_EVENTS_CARD_TYPE$MUL, EVENTS_CARD_TYPE.MULTIPLE_GAMES, eventsAssets.EVENTS_MULTIPLE_GAMES_TITLE), _defineProperty(_EVENTS_CARD_TYPE$MUL, EVENTS_CARD_TYPE.MULTIPLE_ACTIVITIES, eventsAssets.EVENTS_MULTIPLE_ACTIVITIES_TITLE), _defineProperty(_EVENTS_CARD_TYPE$MUL, EVENTS_CARD_TYPE.ALL_GAMES, eventsAssets.EVENTS_ALL_GAMES_TITLE), _EVENTS_CARD_TYPE$MUL)[cardType] || null;
};

var generateMainEventCard = function generateMainEventCard(cardType, eventsAssets) {
  return _objectSpread(_objectSpread({
    Title: generateTitleCard(cardType, eventsAssets)
  }, cardType === EVENTS_CARD_TYPE.SINGLE_GAME && {
    BackgroundCard: eventsAssets.EVENTS_CARD_BLUE,
    GlowCard: eventsAssets.EVENTS_CARD_FRAME_GLOW,
    BottomCard: eventsAssets.EVENTS_CARD_BOTTOM_BLUE,
    BackgroundDetail: eventsAssets.EVENTS_DETAIL_CARD_BLUE,
    EntriesCounterDetail: eventsAssets.EVENTS_DETAIL_COUNTER_BLUE,
    EntriesBarDetail: eventsAssets.EVENTS_DETAIL_BAR_BLUE
  }), {}, {
    Ribbon: {
      Title: eventsAssets.EVENTS_MAIN_RIBBON_TITLE,
      Crown: eventsAssets.EVENTS_MAIN_RIBBON_CROWN,
      Trombone: eventsAssets.EVENTS_MAIN_RIBBON_TROMBONE
    },
    PodiumBottom: eventsAssets.EVENTS_PODIUM_ACTIVE,
    PodiumLightBottom: eventsAssets.EVENTS_PODIUM_LIGHT,
    StrokeColor: '#213787'
  });
};

var generateEndedCard = function generateEndedCard(isMainEvent, eventsAssets, totalEntriesAvailable) {
  return _objectSpread(_objectSpread({
    ShadowCard: eventsAssets.EVENTS_CARD_FRAME_SHADOW,
    BackgroundDetail: eventsAssets.EVENTS_DETAIL_CARD_GREY,
    EntriesCounterDetail: eventsAssets.EVENTS_DETAIL_COUNTER_GREY
  }, totalEntriesAvailable ? {
    EntriesBarDetail: eventsAssets.EVENTS_DETAIL_FILLABLE_BAR_BASE_GREY,
    EntriesBarFillDetail: eventsAssets.EVENTS_DETAIL_FILLABLE_BAR_FILL_GREY,
    EntriesBarEndDetail: eventsAssets.EVENTS_DETAIL_FILLABLE_BAR_CAP_GREY
  } : {
    EntriesBarDetail: eventsAssets.EVENTS_DETAIL_BAR_GREY
  }), {}, {
    Ribbon: isMainEvent ? {
      Title: eventsAssets.EVENTS_MAIN_RIBBON_TITLE_SHADOW,
      Crown: eventsAssets.EVENTS_MAIN_RIBBON_CROWN_SHADOW,
      Trombone: eventsAssets.EVENTS_MAIN_RIBBON_TROMBONE_SHADOW
    } : eventsAssets.EVENTS_DEFAULT_RIBBON_SHADOW,
    PodiumBottom: eventsAssets.EVENTS_PODIUM_DISABLED,
    PodiumLightBottom: null
  });
};

var generateEventsCardDataRendering = function generateEventsCardDataRendering(_ref, eventsAssets) {
  var _EVENTS_CARD_TYPE$SIN;

  var isMainEvent = _ref.isMainEvent,
      cardType = _ref.cardType,
      eventGameplayStatus = _ref.eventGameplayStatus,
      totalEntriesAvailable = _ref.totalEntriesAvailable;
  var generateCard = (_EVENTS_CARD_TYPE$SIN = {}, _defineProperty(_EVENTS_CARD_TYPE$SIN, EVENTS_CARD_TYPE.SINGLE_GAME, generateSingleGameCard(eventsAssets)), _defineProperty(_EVENTS_CARD_TYPE$SIN, EVENTS_CARD_TYPE.MULTIPLE_GAMES, generateMultipleGamesCard(eventsAssets)), _defineProperty(_EVENTS_CARD_TYPE$SIN, EVENTS_CARD_TYPE.MULTIPLE_ACTIVITIES, generateMultipleActivitiesCard(eventsAssets)), _defineProperty(_EVENTS_CARD_TYPE$SIN, EVENTS_CARD_TYPE.TOP_BANNER, generateTopBannerCard(eventsAssets)), _defineProperty(_EVENTS_CARD_TYPE$SIN, EVENTS_CARD_TYPE.ALL_GAMES, generateAllGamesCard(eventsAssets)), _EVENTS_CARD_TYPE$SIN)[cardType];
  if (isMainEvent) generateCard = _objectSpread(_objectSpread({}, generateCard), generateMainEventCard(cardType, eventsAssets));
  if (eventGameplayStatus !== EVENTS_GAMEPLAY_STATUS.RUNNING) generateCard = _objectSpread(_objectSpread({}, generateCard), generateEndedCard(isMainEvent, eventsAssets, totalEntriesAvailable));
  return generateCard;
};

export default generateEventsCardDataRendering;