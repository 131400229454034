import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import QueryParamsProxy from '../../../utils/queryParamProxy';
import RouteParamConstants from '../../../utils/routeParamConstants';
import { EVENT_EMITTER_TYPE } from '../../../constants';
import Emitter from '../../../services/EmitterService';
import { ACTION_COMMANDS_EVENT_TYPE, ACTION_COMMANDS_TYPE } from '../../../api/ActionCommandsInterface/constants';
export default function useEventsTutorial(_ref) {
  var changeInfoState = _ref.changeInfoState;
  var lastSlide = useRef(1);
  var hasTutorial = QueryParamsProxy.findParam(RouteParamConstants.PARAM_SWEEPSTAKE_TUTORIAL, false);
  var actionCommands = useSelector(function (state) {
    return state.actionCommands.actions[0];
  });
  useEffect(function () {
    if (hasTutorial) {
      changeInfoState(true);
      startEventsTutorial();
    }

    (function () {
      return clearParams();
    });
  }, [hasTutorial, changeInfoState, clearParams, startEventsTutorial]);
  var startEventsTutorial = useCallback(function () {
    var _actionCommands$event, _actionCommands$event2;

    Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
      actionType: ACTION_COMMANDS_TYPE.NOTIFY_TRIGGER_PROCESSED_REQUEST,
      data: {
        event: ACTION_COMMANDS_EVENT_TYPE.DISPLAY_EVENTS_TUTORIAL,
        triggerExecution: actionCommands === null || actionCommands === void 0 ? void 0 : (_actionCommands$event = actionCommands.eventsTutorial) === null || _actionCommands$event === void 0 ? void 0 : (_actionCommands$event2 = _actionCommands$event.onDisplayAction) === null || _actionCommands$event2 === void 0 ? void 0 : _actionCommands$event2.triggerExecution
      }
    });
    clearActionCommand(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  var dismissInfoScreen = function dismissInfoScreen() {
    clearParams();

    if (hasTutorial) {
      var _actionCommands$event3, _actionCommands$event4;

      Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
        actionType: ACTION_COMMANDS_TYPE.NOTIFY_TRIGGER_PROCESSED_REQUEST,
        data: {
          event: ACTION_COMMANDS_EVENT_TYPE.SKIP_EVENTS_TUTORIAL,
          triggerExecution: actionCommands === null || actionCommands === void 0 ? void 0 : (_actionCommands$event3 = actionCommands.eventsTutorial) === null || _actionCommands$event3 === void 0 ? void 0 : (_actionCommands$event4 = _actionCommands$event3.onSkipAction) === null || _actionCommands$event4 === void 0 ? void 0 : _actionCommands$event4.triggerExecution,
          payload: lastSlide.current
        }
      });
      clearActionCommand();
    }

    lastSlide.current = 1;
  };

  var clearActionCommand = function clearActionCommand() {
    return Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
      actionType: ACTION_COMMANDS_TYPE.CLEAR_ACTION_COMMAND
    });
  };

  var clearParams = useCallback(function () {
    return QueryParamsProxy.setQueryParams(_defineProperty({}, RouteParamConstants.PARAM_SWEEPSTAKE_TUTORIAL, undefined));
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  return {
    lastSlide: lastSlide,
    dismissInfoScreen: dismissInfoScreen
  };
}