import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useEffect, useState } from 'react';
import { useFormik } from '../../../node_modules/formik';
import { useSelector } from '../../../node_modules/react-redux';
import { validationSchema, registerCardsFormKeys, getFieldsObject, generateInitialValues } from '../utils';
export default function useRegisterCardForm(_ref) {
  var isWeb = _ref.isWeb,
      showProperty = _ref.showProperty,
      submitFormHandler = _ref.submitFormHandler,
      selectedProperty = _ref.selectedProperty,
      userProperty = _ref.userProperty;

  var _useSelector = useSelector(function (state) {
    return state.properties.data;
  }),
      defaultSignupFormSettings = _useSelector.defaultSignupFormSettings;

  var _useState = useState({}),
      _useState2 = _slicedToArray(_useState, 2),
      propertyRequiredFields = _useState2[0],
      changePropertyCat = _useState2[1];

  var initialValues = generateInitialValues(defaultSignupFormSettings, isWeb, userProperty);

  var checkFormValidation = function checkFormValidation() {
    return getFieldsObject((selectedProperty === null || selectedProperty === void 0 ? void 0 : selectedProperty.signupFormSettings) || defaultSignupFormSettings);
  };

  var formikValidation = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema(isWeb, showProperty, propertyRequiredFields),
    onSubmit: submitFormHandler,
    isInitialValid: false,
    enableReinitialize: false
  });
  var values = formikValidation.values,
      setFieldValue = formikValidation.setFieldValue,
      validateForm = formikValidation.validateForm;
  useEffect(function () {
    var propertyRequiredFields = checkFormValidation();
    changePropertyCat(propertyRequiredFields || {}); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperty === null || selectedProperty === void 0 ? void 0 : selectedProperty.propertyId]);
  useEffect(function () {
    validateForm();
  }, [propertyRequiredFields, validateForm]);
  useEffect(function () {
    setFieldValue(registerCardsFormKeys.STATE, '');
  }, [setFieldValue, values[registerCardsFormKeys.COUNTRY]]);
  return _objectSpread(_objectSpread({}, formikValidation), {}, {
    propertyRequiredFields: propertyRequiredFields
  });
}