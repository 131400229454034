import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { ADD_PENDING_MESSAGE, SHIFT_PENDING_MESSAGE, CLAIM_PENDING_MESSAGE, ACTIVATE_PROMOCODE, DISMISS_INVALID_PROMOCODE } from './types';
export var addPendingMessage = function addPendingMessage(message) {
  return {
    type: ADD_PENDING_MESSAGE,
    payload: message
  };
};
export var shiftPendingMessage = function shiftPendingMessage() {
  return {
    type: SHIFT_PENDING_MESSAGE
  };
};
export var claimPendingMessage = function claimPendingMessage(messageIndex, additionalMessageData) {
  return {
    type: CLAIM_PENDING_MESSAGE,
    payload: _objectSpread({
      messageIndex: messageIndex
    }, additionalMessageData)
  };
};
export var activatePromocode = function activatePromocode() {
  return {
    type: ACTIVATE_PROMOCODE
  };
};
export var dismissInvalidPromocode = function dismissInvalidPromocode() {
  return {
    type: DISMISS_INVALID_PROMOCODE
  };
};