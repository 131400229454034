import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { createSlice } from '@reduxjs/toolkit';
var sliceName = 'LAYOUT';
var initialState = {};
var layoutSlice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    setPosition: function setPosition(state, action) {
      var _action$payload = action.payload,
          component = _action$payload.component,
          x = _action$payload.x,
          y = _action$payload.y,
          width = _action$payload.width,
          height = _action$payload.height;
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, component, {
        x: x,
        y: y,
        width: width,
        height: height
      }));
    },
    setDimensions: function setDimensions(state, action) {
      var _action$payload2 = action.payload,
          component = _action$payload2.component,
          width = _action$payload2.width,
          height = _action$payload2.height;
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, component, _objectSpread(_objectSpread({}, state[component]), {}, {
        width: width,
        height: height
      })));
    }
  }
});
var actions = layoutSlice.actions,
    reducer = layoutSlice.reducer;
var setPosition = actions.setPosition,
    setDimensions = actions.setDimensions;
export { setPosition, setDimensions };
export default reducer;