import _slicedToArray from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useState, useEffect } from 'react';
import { handleFilterData } from '../utils/filterData';

var useFilterData = function useFilterData(originalValue, filterData, filterType) {
  var _useState = useState(originalValue || []),
      _useState2 = _slicedToArray(_useState, 2),
      filteredValue = _useState2[0],
      setFilter = _useState2[1];

  useEffect(function () {
    filteredValue && originalValue && setFilter(originalValue.filter(function (item) {
      return handleFilterData[filterType](item, filterData);
    })); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData, originalValue]);
  return {
    filteredValue: filteredValue
  };
};

export default useFilterData;