import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _EVENT_WIDGETS;

import { EVENT_DOMAINS } from './domains';
/**
 * EVENT_WIDGETS defines a specific interactive element of the current domain
 */

export var EVENT_WIDGETS = (_EVENT_WIDGETS = {}, _defineProperty(_EVENT_WIDGETS, EVENT_DOMAINS.GENERAL, {
  MENU_BUTTON: 'MENU_BUTTON',
  GAME_TILE: 'GAME_TILE',
  BACK_TO_LOBBY: 'BACK_TO_LOBBY',
  BUY_COINS: 'BUY_COINS',
  VIDEO_WALL_WIDGET: 'VIDEO_WALL_WIDGET',
  INBOX_WIDGET: 'INBOX_WIDGET',
  HIGH_LIMIT_WIDGET: 'HIGH_LIMIT_WIDGET',
  REWARD_CENTER_WIDGET: 'REWARD_CENTER_WIDGET',
  MISSIONS_WIDGET: 'MISSIONS_WIDGET',
  TUTORIAL_SKIP: 'TUTORIAL_SKIP',
  TUTORIAL_NEXT_STEP: 'TUTORIAL_NEXT_STEP',
  EVENTS_WIDGET: 'EVENTS_WIDGET'
}), _defineProperty(_EVENT_WIDGETS, EVENT_DOMAINS.GAME, {
  SPIN_BUTTON: 'SPIN_BUTTON',
  MAX_BET_BUTTON: 'MAX_BET_BUTTON',
  INCREASE_BET_BUTTON: 'INCREASE_BET_BUTTON',
  DECREASE_BET_BUTTON: 'DECREASE_BET_BUTTON',
  AUTO_SPIN_MENU_BUTTON: 'AUTO_SPIN_MENU_BUTTON'
}), _defineProperty(_EVENT_WIDGETS, EVENT_DOMAINS.MISSIONS, {
  EXPLORER_TAB: 'EXPLORER_TAB',
  EXPERT_TAB: 'EXPERT_TAB',
  BATTLE_TAB: 'BATTLE_TAB',
  INFO_BUTTON: 'INFO_BUTTON',
  MISSION_CARD: 'MISSION_CARD',
  TUTORIAL_SKIP: 'TUTORIAL_SKIP',
  TUTORIAL_NEXT_STEP: 'TUTORIAL_NEXT_STEP',
  MISSION_CARD_REWARD_CLAIM_BUTTON: 'MISSION_CARD_REWARD_CLAIM_BUTTON',
  MISSIONS_INFO_BUTTON: 'MISSIONS_INFO_BUTTON'
}), _defineProperty(_EVENT_WIDGETS, EVENT_DOMAINS.ACTIONS_COMMANDS, {
  ACCEPT_BUTTON: 'ACCEPT_BUTTON',
  SIGN_IN_EMAIL_BUTTON: 'SIGN_IN_EMAIL_BUTTON',
  SIGN_IN_FACEBOOK_BUTTON: 'SIGN_IN_FACEBOOK_BUTTON',
  SIGN_IN_GOOGLE_BUTTON: 'SIGN_IN_GOOGLE_BUTTON',
  SIGN_IN_APPLE_BUTTON: 'SIGN_IN_APPLE_BUTTON',
  DISMISS_BUTTON: 'DISMISS_BUTTON',
  SKIP_ONBOARDING_TUTORIAL: 'SKIP_ONBOARDING_TUTORIAL',
  COMPLETE_ONBOARDING_TUTORIAL: 'COMPLETE_ONBOARDING_TUTORIAL',
  SKIP_MISSIONS_TUTORIAL: 'SKIP_MISSIONS_TUTORIAL',
  COMPLETE_MISSIONS_TUTORIAL: 'COMPLETE_MISSIONS_TUTORIAL'
}), _defineProperty(_EVENT_WIDGETS, EVENT_DOMAINS.EVENTS, {
  EVENTS_CARD: 'EVENTS_CARD',
  EVENTS_INFO_BUTTON: 'EVENTS_INFO_BUTTON',
  EVENTS_TOOLTIP: 'EVENTS_TOOLTIP',
  EVENTS_SKIP_BUTTON: 'EVENTS_SKIP_BUTTON'
}), _EVENT_WIDGETS);