import _slicedToArray from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { AxiosRequest } from './axios';
import { HTTP_REQUEST } from './utils/constants';
var METHODS = ['get', 'put', 'post', 'patch', 'delete'];

var request = _defineProperty({}, HTTP_REQUEST.AXIOS, AxiosRequest);

var httpMethods = METHODS.map(function (method) {
  return function (url) {
    var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var requestConfig = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return request[HTTP_REQUEST.AXIOS](url, method, data, requestConfig);
  };
});

var _httpMethods = _slicedToArray(httpMethods, 5),
    get = _httpMethods[0],
    put = _httpMethods[1],
    post = _httpMethods[2],
    patch = _httpMethods[3],
    del = _httpMethods[4];

export { get, put, post, patch, del };