import _slicedToArray from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useState } from 'react';

function useInfoPopupHandler() {
  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      showInfoModal = _useState2[0],
      setShowInfoModal = _useState2[1];

  var toggleInfoModal = function toggleInfoModal() {
    return setShowInfoModal(function (showInfoModal) {
      return !showInfoModal;
    });
  };

  return {
    showInfoModal: showInfoModal,
    toggleInfoModal: toggleInfoModal
  };
}

export default useInfoPopupHandler;