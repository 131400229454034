import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

/**
 * Converts an API object-array to a JS array.
 * @param  {Object} objectArray An object with sequential, numeric keys.
 * @returns {Array} A JS array.
 */
export default function transformObjectArrayResponse(objectArray) {
  var list = [];
  var sortKey = '__sort_key'; // Is this a (valid) object?

  if (typeof objectArray === 'object' && objectArray !== null) {
    for (var key in objectArray) {
      if (!objectArray.hasOwnProperty(key)) continue;
      var index = list.push(objectArray[key]) - 1;
      list[index] = _objectSpread(_objectSpread({}, list[[index]]), {}, _defineProperty({}, sortKey, parseInt(key)));
    }

    list.sort(function (a, b) {
      return a[sortKey] - b[sortKey];
    });
  }

  return list;
}