import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import _toConsumableArray from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
var _excluded = ["messageIndex"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { ADD_PENDING_MESSAGE, SHIFT_PENDING_MESSAGE, CLAIM_PENDING_MESSAGE, ACTIVATE_PROMOCODE, DISMISS_INVALID_PROMOCODE, CLEAR_TOKEN } from '../actions/types';
var initialState = [];

var pendingMessagesReducer = function pendingMessagesReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  var pendingMessages = _toConsumableArray(state);

  switch (action.type) {
    case ADD_PENDING_MESSAGE:
      if (action.payload.type !== 'PROMO_LINK') {
        // Handling duplicate messages
        var storedMessages = pendingMessages.filter(function (pendingMessage) {
          return pendingMessage.id === action.payload.id;
        });

        if (storedMessages.length === 0) {
          return _toConsumableArray(state).concat(action.payload);
        }

        return _toConsumableArray(state);
      }

      return [].concat(_toConsumableArray(state.filter(function (pendingMessage) {
        return pendingMessage.type !== 'PROMO_LINK';
      })), [action.payload]);

    case CLAIM_PENDING_MESSAGE:
      var _action$payload = action.payload,
          messageIndex = _action$payload.messageIndex,
          additionalData = _objectWithoutProperties(_action$payload, _excluded);

      if (pendingMessages[messageIndex].id) {
        pendingMessages[messageIndex] = _objectSpread(_objectSpread({
          hasClaimed: true
        }, pendingMessages[messageIndex]), additionalData);
      }

      return pendingMessages;

    case SHIFT_PENDING_MESSAGE:
      return pendingMessages.filter(function (pendingMessage) {
        return pendingMessage.hasClaimed !== true;
      });

    case ACTIVATE_PROMOCODE:
      return pendingMessages.map(function (pendingMessage) {
        return pendingMessage.type === 'PROMO_LINK' ? _objectSpread(_objectSpread({}, pendingMessage), {}, {
          active: true
        }) : pendingMessage;
      });

    case DISMISS_INVALID_PROMOCODE:
      return pendingMessages.filter(function (pendingMessage) {
        return pendingMessage.type !== 'PROMO_LINK';
      });

    case CLEAR_TOKEN:
      return [];

    default:
      return state;
  }
};

export default pendingMessagesReducer;