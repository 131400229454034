import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _errorCodeByAuthType;

import { ERROR_EXCEPTION } from '../../constants';
import { EVENT_TYPES } from '../../lib/analytics/constants';
import Logger from '../../lib/analytics/logger';
import { AUTH_TYPES } from '../../utils/authFlow/constants';
import ERROR_CODE from '../../utils/errorCodes';

var handleLoggerErrorEvents = function handleLoggerErrorEvents(error) {
  if (error.name && error.name.includes(ERROR_EXCEPTION.VALIDATION_ERROR)) {
    Logger.sendEvent(EVENT_TYPES.FRONTEND_ERROR, {
      error: error.cause,
      code: ERROR_CODE.JSONSCHEMA_VALIDATION_FAIL
    });
  } else {
    Logger.sendEvent(EVENT_TYPES.FRONTEND_ERROR, {
      error: error.message,
      code: ERROR_CODE.SAGAS_RESPONSE_FAIL
    });
  }
};

export var handleQueryLoggerEvents = function handleQueryLoggerEvents(error) {
  var _error$error, _error$error$data, _error$error2;

  Logger.sendEvent(EVENT_TYPES.FRONTEND_ERROR, {
    error: JSON.stringify((error === null || error === void 0 ? void 0 : (_error$error = error.error) === null || _error$error === void 0 ? void 0 : (_error$error$data = _error$error.data) === null || _error$error$data === void 0 ? void 0 : _error$error$data.errorMessage) || (error === null || error === void 0 ? void 0 : (_error$error2 = error.error) === null || _error$error2 === void 0 ? void 0 : _error$error2.data)),
    code: ERROR_CODE.QUERY_RESPONSE_ERROR
  });
};
export var handleLoginErrorEvents = function handleLoginErrorEvents(data, loginType) {
  Logger.sendEvent(EVENT_TYPES.FRONTEND_ERROR, {
    error: JSON.stringify(data, Object.getOwnPropertyNames(data)),
    code: errorCodeByAuthType[loginType]
  });
};
export var handleSignupErrorEvents = function handleSignupErrorEvents(data) {
  Logger.sendEvent(EVENT_TYPES.FRONTEND_ERROR, {
    error: JSON.stringify(data, Object.getOwnPropertyNames(data)),
    code: ERROR_CODE.SIGNUP_ERROR
  });
};
var errorCodeByAuthType = (_errorCodeByAuthType = {}, _defineProperty(_errorCodeByAuthType, AUTH_TYPES.STANDARD, ERROR_CODE.LOGIN_INTERFACE_LOGIN_CATCH), _defineProperty(_errorCodeByAuthType, AUTH_TYPES.GUEST, ERROR_CODE.LOGIN_INTERFACE_GUEST_LOGIN_CATCH), _defineProperty(_errorCodeByAuthType, AUTH_TYPES.APPLE, ERROR_CODE.LOGIN_INTERFACE_APPLE_LOGIN_CATCH), _defineProperty(_errorCodeByAuthType, AUTH_TYPES.FACEBOOK, ERROR_CODE.LOGIN_INTERFACE_FB_LOGIN_CATCH), _defineProperty(_errorCodeByAuthType, AUTH_TYPES.GOOGLE, ERROR_CODE.LOGIN_INTERFACE_GOOGLE_LOGIN_CATCH), _errorCodeByAuthType);
export default handleLoggerErrorEvents;