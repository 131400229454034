import _slicedToArray from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useCallback, useEffect, useState } from 'react';
import { MISSIONS_COUNTER } from '../../../screens/Missions/constants';
import handlerMissionProgress from '../index';
import PushNotification from '../../../lib/notifications/PushNotification';
import Utils from '../../../utils';
import { useLazyGetMissionsQuery } from '../../../state/query/missions';
import { useDispatch } from 'react-redux';
import { setCurrentProgress } from '../../../state/slices/missions/missions';

var useMissionsProgress = function useMissionsProgress(props) {
  var lastSpinEvents = props.lastSpinEvents,
      currentMission = props.currentMission,
      playerId = props.playerId,
      timedBonus = props.timedBonus;
  var missionGameId = currentMission.missionGameId,
      _currentMission$count = currentMission.counterType,
      counterType = _currentMission$count === void 0 ? null : _currentMission$count,
      counterGoal = currentMission.counterGoal,
      serverProgress = currentMission.serverProgress,
      clientProgress = currentMission.clientProgress;
  var dispatch = useDispatch();

  var _useLazyGetMissionsQu = useLazyGetMissionsQuery(),
      _useLazyGetMissionsQu2 = _slicedToArray(_useLazyGetMissionsQu, 1),
      trigger = _useLazyGetMissionsQu2[0];

  var _useState = useState(clientProgress),
      _useState2 = _slicedToArray(_useState, 2),
      percentage = _useState2[0],
      setPercentage = _useState2[1];

  var isWeb = Utils.getPlatform() === 'web';
  var handlerPercentage = useCallback(function () {
    if (counterType !== MISSIONS_COUNTER.COLLECT_BONUS_X_TIMES) setPercentage(handlerMissionProgress[counterType](lastSpinEvents, counterGoal, clientProgress, missionGameId));
  }, [clientProgress, counterGoal, counterType, lastSpinEvents, missionGameId]);
  useEffect(function () {
    handlerPercentage(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastSpinEvents.createdTimestamp]);
  useEffect(function () {
    if (counterType === MISSIONS_COUNTER.COLLECT_BONUS_X_TIMES && (timedBonus === null || timedBonus === void 0 ? void 0 : timedBonus.success)) setPercentage(serverProgress); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timedBonus]);
  useEffect(function () {
    Math.floor(percentage) === 100 || Math.floor(percentage) > 100 ? setTimeout(function () {
      trigger({
        requestAssetsData: false,
        playerId: playerId
      });
    }, 2000) : dispatch(setCurrentProgress(percentage));
  }, [dispatch, percentage, playerId, trigger]);
  useEffect(function () {
    if (Math.round(clientProgress) < Math.round(serverProgress)) dispatch(setCurrentProgress(serverProgress));
  }, [clientProgress, dispatch, serverProgress]);
  useEffect(function () {
    if (!isWeb && serverProgress >= 100) PushNotification.cancelMissionReminderNotification(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverProgress]);
  return {
    clientProgress: clientProgress,
    serverProgress: serverProgress
  };
};

export default useMissionsProgress;