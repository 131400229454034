import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _schemas;

import { Endpoints } from '../../../api/Constants';
import getFbAppId from './getFbAppId';
import getTermsAndConditions from './getTermsAndConditions';
import getProgressiveJackpots from './getProgressiveJackpots';
import getAssets from './getAssets';
import getMissions from './getMissions';
import postMissions from './postMissions';
import initProperty from './initProperty';
import dailyWheel from './dailyWheel';
import getGameRooms from './getGameRooms';
import postLinkGuestAccount from './postLinkGuestAccount';
import getEvents from './getEvents';
var schemas = (_schemas = {}, _defineProperty(_schemas, Endpoints.GET_FB_APP_ID, {
  GET: getFbAppId
}), _defineProperty(_schemas, Endpoints.TERMS_AND_CONDITIONS, {
  GET: getTermsAndConditions
}), _defineProperty(_schemas, Endpoints.PROGRESSIVE_JACKPOTS, {
  GET: getProgressiveJackpots
}), _defineProperty(_schemas, Endpoints.ASSETS, {
  GET: getAssets
}), _defineProperty(_schemas, Endpoints.MISSIONS_PLAYER, {
  GET: getMissions,
  POST: postMissions
}), _defineProperty(_schemas, Endpoints.PROPERTIES, {
  GET: initProperty
}), _defineProperty(_schemas, Endpoints.GET_DAILY_WHEEL_INFO, {
  GET: dailyWheel
}), _defineProperty(_schemas, Endpoints.GET_GAME_ROOMS, {
  GET: getGameRooms
}), _defineProperty(_schemas, Endpoints.LINK_GUEST_ACCOUNT_EMAIL, {
  POST: postLinkGuestAccount
}), _defineProperty(_schemas, Endpoints.LINK_GUEST_ACCOUNT_GOOGLE, {
  POST: postLinkGuestAccount
}), _defineProperty(_schemas, Endpoints.LINK_GUEST_ACCOUNT_APPLE, {
  POST: postLinkGuestAccount
}), _defineProperty(_schemas, Endpoints.LINK_GUEST_ACCOUNT_FACEBOOK, {
  POST: postLinkGuestAccount
}), _defineProperty(_schemas, Endpoints.EVENTS_PLAYER, {
  GET: getEvents
}), _schemas);
export var getSchema = function getSchema(id, method) {
  return schemas[id][method];
};