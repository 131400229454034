import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { SET_PRE_PURCHASE_ROUTE, SET_CARD_NICKNAME, SET_PROMO_CODE, CLEAR_PRE_PURCHASE_STORE, SET_PRE_PURCHASE_ROUTE_PARAMS, SET_PURCHASE_TYPE, IS_OUT_OF_COINS_PURCHASE, IS_LTO_PURCHASE, SET_FORM_URL, SET_ACCESS_CODE, SET_TRANSACTION_ID, SET_PURCHASE_PROFILE } from '../actions/types';
var initialState = {
  cardNickname: '',
  route: '',
  routeParams: {},
  promoCode: '',
  purchaseType: '',
  outOfCoinsPurchase: false,
  isLTOPurchase: false,
  purchaseProfile: []
};
export var prePurchaseReducer = function prePurchaseReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_PRE_PURCHASE_ROUTE:
      return _objectSpread(_objectSpread({}, state), {}, {
        route: action.payload
      });

    case SET_PRE_PURCHASE_ROUTE_PARAMS:
      return _objectSpread(_objectSpread({}, state), {}, {
        routeParams: action.payload
      });

    case SET_CARD_NICKNAME:
      return _objectSpread(_objectSpread({}, state), {}, {
        cardNickname: action.payload
      });

    case SET_PROMO_CODE:
      return _objectSpread(_objectSpread({}, state), {}, {
        promoCode: action.payload
      });

    case SET_PURCHASE_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, {
        purchaseType: action.payload
      });

    case IS_OUT_OF_COINS_PURCHASE:
      return _objectSpread(_objectSpread({}, state), {}, {
        outOfCoinsPurchase: action.payload
      });

    case IS_LTO_PURCHASE:
      return _objectSpread(_objectSpread({}, state), {}, {
        ltoPurchase: action.payload
      });

    case SET_FORM_URL:
      return _objectSpread(_objectSpread({}, state), {}, {
        formUrl: action.payload
      });

    case SET_ACCESS_CODE:
      return _objectSpread(_objectSpread({}, state), {}, {
        accessCode: action.payload
      });

    case SET_TRANSACTION_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        transactionId: action.payload
      });

    case SET_PURCHASE_PROFILE:
      return _objectSpread(_objectSpread({}, state), {}, {
        purchaseProfile: action.payload
      });

    case CLEAR_PRE_PURCHASE_STORE:
      return initialState;

    default:
      return state;
  }
};